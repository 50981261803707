// imports top
@import (less) "config.less";
@import (inline) "normalize.css";
@import (inline) "reset.css";
@import (inline) "icomoon.css";
@import (less) "typografy.less";
@import (inline) "aos.css";
@import (inline) "slick.css";
@import (inline) "selectric.css";
@import (inline) "magnific-popup.css";
@import (inline) "jquery.mCustomScrollbar.css";
@import (inline) "ion.rangeSlider.min.css";
@import (less) "plugins.less";
body,
html {
  height: 100%;
}
body {
  font: 16px/1.3 @primary-font;
  transition: color @primary-transition;
  .bg {

    height: 794px;
    content: "";
    background-image: url(../img/inner-bg.jpg);
	background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .59;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;

    &:after {
      height: 794px;
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }
  &.light {
    color: @text-color;
    background: @wild-sand;
    .bg {
      &:after {
        background: linear-gradient(rgba(246, 246, 246, 0) 0%, @wild-sand 100%);
      }
    }
  }
  &.dark {
    color: @elements-color;
    background: @mirage;
    .bg {
      &:after {
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, @mirage 100%);
      }
    }
  }
}
.wrapper {
  height: 1px;
  min-height: 100%;
  flex-flow: column nowrap;
  display: flex;
}
.main {
  width: 100%;
  max-width: 1310px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  &--hold {
    padding: 0;
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
  }
}
.header {
  background: @bunker;
  padding: 0 5px;
  position: relative;
  z-index: 2;
  flex: none;
  &__left {
    float: left;
  }
  &__right {
    float: right;
  }
  .main {
    max-width: none;
  }
}
.site {
  &_logo {
    height: 82px;
    margin: 0 75px 0 0;
    align-items: center;
    display: inline-flex;
    float: left;
    &__mobile {
      display: none;
    }
  }
  &_external {
    font: 16px/1.2 @primary-font;
    text-decoration: none;
    transition: color @primary-transition;
    margin-right: 18px;
    align-items: center;
    display: inline-flex;
    .light & {
      color: @text-color;
    }
    .dark & {
      color: @elements-color;
    }
    .header & {
      height: 82px;
      color: @elements-color;
      float: left;
    }
    .headline & {
      margin-bottom: 20px;
      margin-left: 15px;
    }
    span {
      transition: border @primary-transition;
      .light & {
        border-bottom: 1px dashed fade(@text-color, 50%);
      }
      .dark &,
      .header & {
        border-bottom: 1px dashed fade(@elements-color, 50%);
      }
    }
    [class^=icon-] {
      font-size: 13px;
      margin: 0 -18px 0 5px;
      float: left;
    }
    &:hover {
      color: @deco-color;
      span {
        border-bottom: 1px dashed fade(@deco-color, 50%);
      }
    }
  }
  &_switch {
    height: 82px;
    text-decoration: none;
    align-items: center;
    display: inline-flex;
    float: left;
    &__wrap {
      height: 36px;
      font: 16px/1.2 @primary-font;
      border-radius: 18px;
      align-items: center;
      display: flex;
      .light & {
        color: @text-color;
        background: fade(@elements-color, 90%);
        border: 1px solid fade(@elements-color, 90%);
      }
      .dark & {
        color: @elements-color;
        border: 1px solid @border-color;
      }
      &--night {
        padding: 2px 10px 2px 2px;
        .light & {
          display: none;
        }
        [class^=icon-] {
          margin: 0 5px 0 0;
        }
      }
      &--day {
        padding: 2px 2px 2px 10px;
        .dark & {
          display: none;
        }
        [class^=icon-] {
          margin: 0 0 0 5px;
        }
      }
      [class^=icon-] {
        width: 30px;
        height: 30px;
        color: @text-color;
        font-size: 18px;
        background: @elements-color;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, .25));
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: inline-flex;
      }
    }
  }
  &_user {
    height: 82px;
    color: @elements-color;
    font-size: 18px;
    text-decoration: none;
    transition: all @primary-transition;
    padding: 0 15px;
    margin: 0 0 0 15px;
    align-items: center;
    display: inline-flex;
    float: left;
    &.active {
      .light & {
        color: @text-color;
        background: @elements-color;
      }
      .dark & {
        color: @elements-color;
        background: @ebony-clay;
      }
    }
    &:hover {
      color: @deco-color;
    }
    &__txt {
      font: 500 16px/1.2 @primary-font;
      padding: 0 15px 0 0;
    }
  }
  &_close {
    width: 32px;
    height: 32px;
    color: @elements-color;
    font-size: 0;
    text-align: center;
    text-decoration: none;
    background: @deco-color;
    position: absolute;
    top: 28px;
    right: 28px;
    &:hover {
      [class^=icon-] {
        transform: rotate(180deg);
      }
    }
    [class^=icon-] {
      width: 32px;
      font-size: 15px;
      line-height: 32px;
      transition: transform @primary-transition;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &_back {
    font: 16px/1.2 @primary-font;
    transition: color @primary-transition;
    text-decoration: none;
    margin: 0 0 20px 0;
    align-items: center;
    display: inline-flex;
    .light & {
      color: @text-color;
    }
    .dark & {
      color: @elements-color;
    }
    &:hover {
      color: @deco-color;
    }
    [class^=icon-] {
      font-size: 10px;
      margin: 0 10px 0 0;
    }
    .breadcrumb + & {
      margin-top: 30px;
    }
  }
  &_scroll {
    transition: transform @primary-transition;
    transform: translateX(-50%) translateY(0);
    position: absolute;
    left: 50%;
    bottom: 33px;
    &:hover {
      transform: translateX(-50%) translateY(10px);
    }
  }
}
.hamburger {
  height: 82px;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity;
  position: relative;
  align-items: center;
  display: none;
  float: left;
  &__box {
    width: 33px;
    height: 19px;
    position: relative;
    display: block;
  }
  &__inner {
    top: 50%;
    margin-top: -2px;
    display: block;
  }
  &__inner,
  &__inner:before,
  &__inner:after {
    width: 33px;
    height: 3px;
    background: @elements-color;
    position: absolute;
    transition: transform .15s ease;
  }
  &:hover &__inner,
  &:hover &__inner:before,
  &:hover &__inner:after {
    background: @deco-color;
  }
  &__inner:before,
  &__inner:after {
    content: "";
    display: block;
  }
  &__inner:before {
    top: -8px;
  }
  &__inner:after {
    top: -16px;
  }
  &--collapse &__inner {
    top: auto;
    bottom: 0;
    transition: transform .13s cubic-bezier(.55, .055, .675, .19) .13s, background @primary-transition;
    &:after {
      transition: top .2s .2s cubic-bezier(.33333, .66667, .66667, 1), opacity .1s linear, background @primary-transition;
    }
    &:before {
      transition: top .12s .2s cubic-bezier(.33333, .66667, .66667, 1), transform .13s cubic-bezier(.55, .055, .675, .19), background @primary-transition;
    }
  }
  &--collapse.active &__inner,
  a.active &--collapse &__inner {
    background: @deco-color;
    transform: translate3d(0, -7px, 0) rotate(-45deg);
    transition-delay: .22s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    &:after {
      background: @deco-color;
      top: 0;
      opacity: 0;
      transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s .22s linear;
    }
    &:before {
      background: @deco-color;
      top: 0;
      transform: rotate(-90deg);
      transition: top .1s .16s cubic-bezier(.33333, 0, .66667, .33333), transform .13s .25s cubic-bezier(.215, .61, .355, 1);
    }
  }
}
.nav {
  &_main {
    float: left;
    &__items {
      padding: 0 25px 0 0;
      float: left;
    }
    &__links {
      height: 82px;
      color: @elements-color;
      font: 300 16px/1.2 @primary-font;
      text-decoration: none;
      text-transform: uppercase;
      transition: color @primary-transition;
      align-items: center;
      display: flex;
      &:hover {
        color: @deco-color;
      }
      &.active {
        font-weight: bold;
      }
    }
  }
  &_lang {
    margin: 0 10px;
    float: left;
    &__items {
      position: relative;
    }
    &__links {
      height: 82px;
      color: @elements-color;
      font: bold 16px/1.2 @primary-font;
      text-decoration: none;
      text-transform: uppercase;
      transition: color @primary-transition;
      padding: 0 13px;
      align-items: center;
      display: flex;
      &.active,
      &:hover {
        color: @deco-color;
      }
      [class^=icon-] {
        font-size: 6px;
        margin: 0 0 0 4px;
      }
    }
    &__sub {
      opacity: 0;
      visibility: hidden;
      background: @bunker;
      transition: all @primary-transition;
      padding: 10px 0;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      &.open {
        opacity: 1;
        visibility: visible;
      }
      &__items, li {
        display: block;
      }
      &__links, a {
        color: @elements-color;
        font: 16px/1.2 @primary-font;
        text-decoration: none;
        text-transform: uppercase;
        transition: color @primary-transition;
        padding: 0 13px;
        display: block;
        &:hover {
          color: @deco-color;
        }
      }
    }
  }
  &_side {
    padding: 12px 0;
    margin: 15px 0;
    &__links {
      font: 16px/1.2 @primary-font;
      text-decoration: underline;
      transition: color @primary-transition;
      padding: 9px 15px 9px 32px;
      display: block;
      .light & {
        color: fade(@text-color, 80%);
        &.active,
        &:hover {
          color: @text-color;
        }
      }
      .dark & {
        color: fade(@elements-color, 80%);
        &.active,
        &:hover {
          color: @elements-color;
        }
      }
      &.active,
      &:hover {
        text-decoration: none;
      }
      &.active {
        font-weight: bold;
      }
    }
  }
  &_bottom {
    font-size: 0;
    margin: 20px -10px 0 0;
    order: 1;
    &__items {
      border-left: 1px solid fade(@elements-color, 50%);
      padding: 0 10px;
      display: inline-block;
      &:first-child {
        border: none;
      }
    }
    &__links {
      color: fade(@elements-color, 80%);
      font: 14px/1.2 @primary-font;
      text-decoration: none;
      transition: color @primary-transition;
      &:hover {
        color: @deco-color;
      }
    }
  }
}
.login {
  h3 {
    font-size: 28px;
    align-items: center;
    display: flex;
    [class^=icon-] {
      font-size: 39px;
      margin: 0 10px 0 0;
    }
  }
  &_hold {
    position: relative;
    float: left;
  }
  &_box {
    width: 346px;
    max-width: 346px;
    opacity: 0;
    visibility: hidden;
    transition: all @primary-transition;
    box-shadow: 0 13px 26px rgba(0, 0, 0, .72);
    padding: 32px 32px 32px 32px;
    position: absolute;
    top: 100%;
    right: 0;
    .light & {
      color: @text-color;
      background: @elements-color;
    }
    .dark & {
      color: @elements-color;
      background: @ebony-clay;
    }
    &.open {
      opacity: 1;
      visibility: visible;
    }
    &__items {
      padding: 20px 0 0 0;
      &:first-child {
        padding: 0;
      }
      h4 {
        font: bold 16px/1.2 @primary-font;
        padding: 0 0 14px 0;
      }
    }
  }
}
.body {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  &--inner {
    padding: 0 0 40px 0;
  }
}
.section {
  transition: background @primary-transition;
  padding: 45px 0;
  &--content {
    width: 71.86%;
    color: @text-color;
    background: @elements-color;
    padding: 0;
    margin: 0 0 45px 0;
    float: left;
  }
  &--bg {
    .light & {
      background: @elements-color;
    }
    .dark & {
      background: @bunker;
    }
  }
  &--shadow {
    box-shadow: 0 3px 36px rgba(0, 0, 0, .5);
    position: relative;
  }
  &--city {
    .light & {
      background: url(../img/home-bg-light-city.jpg) no-repeat center center / cover;
    }
    .dark & {
      background: url(../img/home-bg-dark-city.jpg) no-repeat center center / cover;
    }
  }
  &--intro-new {
    background: url(../img/intro_new.jpg) no-repeat center center / cover;
    box-shadow: 0 7px 17px rgba(0, 0, 0, .51);
    position: relative;
    padding: 110px 0 315px 0;
    z-index: 1;
  }
  &--intro-old {
    background: url(../img/intro_old.jpg) no-repeat center center / cover;
    padding: 200px 0 224px 0;
    position: relative;
  }
  &__block {
    padding: 45px 55px 20px 55px;
	figcaption{
		margin: 0 -55px;
		color: @elements-color;
		font: 13px/1.4 @primary-font;
		background: @deco-color;
		padding: 8px 15px;
		&+h2, &+p{
			margin-top: 18px;
		}
	}
    &:first-of-type {
      padding-right: 110px;
      .share,
	  .addtoany_content{
        margin-right: -80px;
      }
	  figcaption{
		margin-right:-110px;
	  }
	  @media (min-width: @tablet-min){
		  .img_center{
			margin-right:-111px;
		  }
	  }
    }
	.img_center{
		margin: 0 -55px;
	}
    &--bg {
      background: @spanish-white;
    }
	a, a:not([class]){
	  color:#314fe2;
	  text-decoration:none;
	  font-weight:700;
	}
  }
  &__caption {
    color: @elements-color;
    font: 13px/1.4 @primary-font;
    background: @deco-color;
    padding: 8px 15px;
  }
  &__video {
    position: relative;
    display: block;
    [class^=icon-] {
      width: 92px;
      height: 68px;
      color: @elements-color;
      font-size: 34px;
      line-height: 68px;
      text-align: center;
      background: @deco-color;
      box-shadow: 0 3px 26px rgba(0, 0, 0, .81);
      padding: 0 0 0 5px;
      position: absolute;
      top: ~"calc(50% - 34px)";
      left: ~"calc(50% - 46px)";
    }
    &:hover {
      [class^=icon-] {
        border-color: @deco-color;
      }
    }
    img {
      width: 100%;
    }
  }
  iframe{
	max-width:100%;
  }
}
.home {
  &_slider {
    z-index: 1;
    &--biograma {
      height: 100%;
      .slick-list,
      .slick-track {
        height: 100%;
      }
    }
    &__panels {
      height: 360px;
      transition: color @primary-transition;
      align-items: center;
      display: flex !important;
      @media (min-width: (@tablet-max + 161px)) {
        height: 476px;
      }
      .light & {
        color: @text-color;
        &:before {
          background: linear-gradient(to right, rgba(255, 255, 255, .83) 0%, rgba(255, 255, 255, 0) 70%);
        }
      }
      .dark & {
        color: @elements-color;
        &:before {
          background: linear-gradient(to right, #020202 0%, rgba(255, 255, 255, 0) 70%);
        }
      }
	  &--biograma {
        height: 100%;
        transition: background @primary-transition;
        background-blend-mode: multiply;
        padding-top: 60px;
        padding-bottom: 80px;
        .light & {
          color: @elements-color;
          background-color: #ffff4d;
        }
        .dark & {
          background-color: #68171a;
        }
        &:before {
          display: none;
        }
      }
      &:before {
        content: "";
        transition: background @primary-transition;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
		z-index: 1;
      }
      @media (min-width: @tablet-min) and (max-width: (@tablet-max + 200px)) {
        .main {
          padding-left: 95px;
          padding-right: 95px;
        }
      }
      h2 {
        padding: 0 0 45px 0;
      }
	  p {
        padding: 0 0 35px 0;
      }
	  .icon-arrow-short-next {
        font-size: 10px;
        margin: 0 0 0 5px;
      }
      &:not(&--biograma) {
		.btn {
			height: 52px;
			font-size: 16px;
			letter-spacing: 1px;
			[class^=icon-] {
				font-size: 14px;
				margin: 0 8px 0 0;
			}
		}
	  }
    }
    &__hold {
      max-width: 620px;
      position: relative;
      z-index: 2;
    }
    &__type {
      font: 900 14px/1.2 @primary-font;
      text-transform: uppercase;
      letter-spacing: .18em;
      transition: color @primary-transition;
      margin: -40px 0 30px 0;
      align-items: center;
      display: flex;
      .light & {
        color: fade(@text-color, 50%);
      }
      .dark & {
        color: fade(@elements-color, 50%);
      }
      [class^=icon-] {
        font-size: 28px;
        transition: color @primary-transition;
        margin: 0 10px 0 0;
        .light & {
          color: @text-color;
        }
        .dark & {
          color: @elements-color;
        }
      }
    }
	&__media {
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
    }
    .slick-prev {
      left: 30px;
    }
    .slick-next {
      right: 30px;
    }
    .slick-dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 28px;
      li {
        button {
          .light & {
            background: @text-color;
          }
          .dark & {
            background: @elements-color;
          }
        }
      }
    }
  }
  &_video {
    width: 51%;
    text-decoration: none;
    position: relative;
    float: left;
    img {
      width: 100%;
    }
    [class^=icon-] {
      width: 65px;
      height: 65px;
      color: @deco-color;
      font-size: 39px;
      line-height: 60px;
      text-align: center;
      background: fade(@bunker, 50%);
      transition: border @primary-transition;
      border: 2px solid @elements-color;
      border-radius: 50%;
      padding: 0 0 0 10px;
      position: absolute;
      top: ~"calc(50% - 32px)";
      left: ~"calc(50% - 32px)";
    }
    &:hover {
      [class^=icon-] {
        border-color: @deco-color;
      }
    }
  }
  &_text {
    width: 49%;
    padding: 16px 0 0 45px;
    float: right;
  }
}
.intro {
  width: 100%;
  max-width: 920px;
  text-align: center;
  position: relative;
  margin: 0 auto;
  z-index: 3;
  &--new {
    backdrop-filter: blur(30px);
    background: rgba(255, 255, 255, .15);
    padding: 58px 30px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
    }
    &:before {
      border-left: 1px solid fade(@elements-color, 50%);
      border-right: 1px solid fade(@elements-color, 50%);
    }
    &:after {
      border-bottom: 1px solid fade(@elements-color, 50%);
    }
    h1 {
      padding: 0;
    }
  }
  &--old {
    color: @text-color;
    background: @spanish-white;
    filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, .58));
    padding: 32px 100px 44px 100px;
    overflow: hidden;
    h2 {
      font: 28px/1.3 @secondary-font;
      padding: 0 0 35px 0;
    }
    .btn {
      height: 50px;
      font-size: 19px;
    }
  }
  &__icon {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    &--new {
      position: absolute;
      top: -8px;
      left: 10px;
      right: 10px;
      &:before,
      &:after {
        width: 100%;
        height: 1px;
        content: "";
        background: fade(@elements-color, 50%);
        flex: 1 1 0;
      }
      [class^=icon-] {
        font-size: 34px;
        margin: 0 15px;
      }
    }
    &--old {
      color: fade(@bunker, 50%);
      margin: 0 0 20px 0;
      [class^=icon-] {
        font-size: 35px;
        margin: 0 15px;
      }
    }
    &__line {
      width: ~"calc(100% + 100px)";
      height: 1px;
      background: @natural-gray;
      position: relative;
      &--left {
        margin: 0 0 0 -100px;
        &:before {
          right: 0;
        }
      }
      &--right {
        margin: 0 -100px 0 0;
        &:before {
          left: 0;
        }
      }
      &:before {
        width: 6px;
        height: 6px;
        content: "";
        background: @natural-gray;
        transform: rotate(45deg);
        position: absolute;
        top: -3px;
      }
    }
  }
  &__top_deco,
  &__bottom_deco {
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    z-index: 2;
  }
  &__top_deco {
    top: -10px;
  }
  &__bottom_deco {
    bottom: 33px;
  }
}
.tabs {
  margin: 0 0 20px 0;
  &_nav {
    &_hold {
      transition: border @primary-transition;
      margin: 0 0 40px 0;
      position: relative;
      justify-content: space-between;
      display: flex;
      .light & {
        border-bottom: 4px solid fade(@bunker, 15%);
      }
      .dark & {
        border-bottom: 4px solid fade(@regent-gray, 11%);
      }
    }
    &_toggle {
      width: 40px;
      height: 40px;
      font: bold 18px/1.2 @primary-font;
      .light & {
        color: @text-color;
        background: @mercury;
      }
      .dark & {
        color: @elements-color;
        background: @ebony-clay;
      }
      &.hidden {
        display: none;
      }
    }
    &_hidden {
      width: 310px;
      opacity: 0;
      visibility: hidden;
      transition: all @primary-transition;
      padding: 32px 30px;
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 2;
      &.open {
        opacity: 1;
        visibility: visible;
      }
      .light & {
        background: @mercury;
      }
      .dark & {
        background: @ebony-clay;
      }
    }
    overflow: hidden;
    flex: 1;
    &__items {
      margin: 0 1px 0 0;
      float: left;
      &:last-child {
        margin: 0;
      }
    }
    &_hidden &__items {
      width: 100%;
      margin: 1px 0;
    }
    &__links {
      height: 40px;
      font: bold 18px/1.2 @primary-font;
      text-decoration: none;
      text-transform: uppercase;
      transition: all @primary-transition;
      padding: 9px 14px;
      position: relative;
      align-items: center;
      display: flex;
      .light & {
        color: @text-color;
        background: @elements-color;
        &:hover {
          color: @elements-color;
          background: @text-color;
        }
        &.active {
          color: @elements-color;
          background: @text-color;
          &:after {
            background: fade(@bunker, 50%);
          }
        }
      }
      .dark & {
        color: @elements-color;
        &:hover {
          color: @text-color;
          background: @elements-color;
        }
        &.active {
          color: @text-color;
          background: @elements-color;
          &:after {
            background: fade(@regent-gray, 50%);
          }
        }
      }
      &--mobile {
        display: none;
      }
      [class^=icon-] {
        font-size: 32px;
        margin: 0 10px 0 0;
      }
      &:after {
        height: 4px;
        content: "";
        transition: background @primary-transition;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -4px;
      }
    }
    &_hidden &__links {
      height: auto;
      font: bold 14px/1.2 @primary-font;
      background: none;
      padding: 8px 10px 6px 10px;
      &:after {
        display: none;
      }
    }
  }
  &_sub_nav {
    &__items {
      margin: 0 3px 0 0;
      float: left;
    }
    &__links {
      font: bold 16px/1.2 @primary-font;
      text-decoration: none;
      text-transform: uppercase;
      transition: all @primary-transition;
      padding: 15px 30px;
      float: left;
      .light & {
        color: fade(@text-color, 50%);
        background: fade(@regent-gray, 9%);
        &.active,
        &:hover {
          color: @text-color;
          background: @elements-color;
        }
      }
      .dark & {
        color: fade(@elements-color, 50%);
        background: fade(@shuttle-gray, 9%);
        &.active,
        &:hover {
          color: @elements-color;
          background: @ebony-clay;
        }
      }
      &--mobile {
        display: none;
      }
    }
  }
  &_panels {
    clear: both;
    display: none;
  }
}
.media_list {
  margin: 0 -5px 0 0;
  display: flex;
  flex-flow: row wrap;
  &__items {
    width: 20%;
    padding: 0 5px 20px 0;
  }
  &__links {
    color: @elements-color;
    text-decoration: none;
    transition: color @primary-transition;
    display: block;
    &:hover {
      color: fade(@elements-color, 80%);
    }
    h3 {
      font: bold 16px/1.24 @primary-font;
      transition: color @primary-transition;
      padding: 0 0 14px 0;
      .light & {
        color: @text-color;
      }
      .dark & {
        color: @elements-color;
      }
    }
    &__thumb {
      transition: all @primary-transition;
      margin: 0 0 12px 0;
      position: relative;
      display: block;
      &:hover {
        transform: scale(1.08);
        z-index: 1;
      }
      &:before {
        content: "";
        opacity: .5;
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, @black 100%);
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 136px;
      }
      [class^=icon-] {
        color: @deco-color;
        font-size: 13px;
        position: absolute;
        left: 11px;
        bottom: 11px;
      }
    }
    &__timer {
      font: 900 14px/1.2 @primary-font;
      letter-spacing: .18em;
      position: absolute;
      right: 11px;
      bottom: 11px;
    }
  }
}
.story_list {
  margin: 0 -5px 0 0;
  display: flex;
  flex-flow: row wrap;
  &__items {
    width: 25%;
    padding: 0 5px 20px 0;
  }
  &__links {
    text-decoration: none;
    transition: all @primary-transition;
    position: relative;
    display: block;
    &:hover {
      transform: scale(1.08);
      z-index: 1;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .light & {
      color: @text-color;
      box-shadow: 0 13px 26px rgba(0, 0, 0, .2);
      &:before {
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, @elements-color 100%);
      }
    }
    .dark & {
      color: @elements-color;
      box-shadow: 0 13px 26px rgba(0, 0, 0, .56);
      &:before {
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, @bunker 100%);
      }
    }
    h3 {
      font: bold 19px/1.4 @primary-font;
      padding: 0 0 14px 0;
    }
    img {
      width: 100%;
    }
    &__bottom {
      padding: 20px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &--additional &__links {
    box-shadow: none;
    border: 1px solid @border-color;
    .light & {
      background: @elements-color;
      &:before {
        background: linear-gradient(@elements-color 12%, rgba(0, 0, 0, 0) 100%);
      }
    }
    .dark & {
      background: @bunker;
      &:before {
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, @bunker 60%);
      }
    }
    &:hover {
      transform: scale(1);
    }
    &:before {
      top: 0;
    }
    h3 {
      height: ~"calc(100% - 100px)";
      font: 28px/1.3 @secondary-font;
      text-align: center;
      padding: 0;
      align-items: center;
      display: flex;
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      justify-content: center;
    }
  }
  &--additional &__links__bottom {
    text-align: center;
    padding: 20px 20px 38px 20px;
  }
}
.partners_slider {
  padding: 35px 0 0 0;
  clear: both;
  &__panels {
    height: 142px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    display: flex !important;
    img {
      max-width: 166px;
      max-height: 100%;
    }
  }
}
.breadcrumb {
  padding: 20px 0 10px 0;
  &__items {
    font: 14px/1.2 @primary-font;
    transition: color @primary-transition;
    margin: 0 0 7px 0;
    float: left;
    .light & {
      color: fade(@bunker, 50%);
    }
    .dark & {
      color: fade(@elements-color, 50%);
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:after {
      content: "›";
      padding: 0 5px;
      float: left;
    }
  }
  &__links {
    text-decoration: none;
    transition: color @primary-transition;
    float: left;
    .light & {
      color: fade(@bunker, 50%);
      &:hover {
        color: @bunker;
      }
    }
    .dark & {
      color: fade(@elements-color, 50%);
      &:hover {
        color: @elements-color;
      }
    }
  }
}
h3.tag-links {
  a:not(:first-child) {
    padding-left: 16px;
  }
}
.tags {
  padding: 0 0 15px 0;
  &__items {
    margin: 0 5px 5px 0;
    float: left;
  }
  &__links {
    height: 32px;
    font: 15px/1.2 @primary-font;
    text-decoration: none;
    vertical-align: top;
    transition: all @primary-transition;
    padding: 7px 12px;
    align-items: center;
    display: flex;
    .light & {
      color: @text-color;
      background: @mercury;
      &:hover {
        color: @elements-color;
      }
    }
    .dark & {
      color: @elements-color;
      background: @ebony-clay;
    }
    &:hover {
      background: @deco-color;
    }
	.light .map_info_box &,
    .dark .map_info_box & {
      color: @text-color;
      background: none;
      padding: 0;
    }
    [class^=icon-] {
      font-size: 6px;
      background: @bunker;
      border-radius: 50%;
      padding: 5px;
      margin: 0 0 0 10px;
    }
    &__no {
      color: @regent-gray;
      transition: color @primary-transition;
      margin: 0 0 0 5px;
    }
    &:hover &__no {
      color: @elements-color;
    }
  }
}
.share,
.section__block .addtoany_content {
  margin: -22px -25px 15px 0;
  justify-content: flex-end;
  display: flex;
  &__items,
  .addtoany_list a{
    margin: 0 0 0 10px;
  }
  &__links {
    float: left;
  }
}
.filter {
  &_hold {
    transition: background @primary-transition;
    margin: 0 0 30px 0;
    display: flex;
	&--tight {
      width: 315px;
      filter: drop-shadow(0px 13px 61px rgba(0, 0, 0, 0.1));
      margin: 0;
      flex-flow: column nowrap;
      float: left;
    }
    .light & {
      background: @elements-color;
    }
    .dark & {
      background: @ebony-clay;
    }
  }
  &_nav {
    border-right: 1px solid @border-color;
    padding: 16px;
    flex: 0 0 235px;
	[class*=_hold--tight] & {
      border-right: none;
      border-bottom: 1px solid @border-color;
      flex: 0 0 auto;
    }
    &__items {
      padding: 0 0 1px 0;
    }
    &__links {
      font: bold 15px/1.2 @primary-font;
      text-decoration: none;
      transition: all @primary-transition;
      padding: 7px 10px;
      display: block;
      .light & {
        color: @text-color;
      }
      .dark & {
        color: @elements-color;
      }
      &--mobile {
        display: none;
      }
      &.active,
      &:hover {
        color: @elements-color;
        background: @deco-color;
      }
    }
  }
  &_container {
    padding: 16px;
    flex: 1 1 0;
  }
  &_wrap {
    width: ~"calc(100% - 426px)";
    float: left;
	[class*=_hold--tight] & {
      width: 100%;
      margin: 0;
    }
  }
  &_toggle {
    display: none;
  }
  &_elements {
    display: flex;
    flex-flow: row wrap;
    &__items {
      width: 50%;
      padding: 0 10px 1px 0;
	  [class*=_hold--tight] & {
        width: 100%;
        padding-right: 0;
      }
      input[type=checkbox] {
        position: absolute;
        opacity: 0;
        z-index: -1;
        & + label {
          min-height: 20px;
          font: 15px/1.2 @primary-font;
          text-align: left;
          cursor: pointer;
          transition: background @primary-transition;
          white-space: pre-wrap;
          padding: 5px 10px;
          position: relative;
          align-items: center;
          justify-content: flex-start;
          display: inline-flex;
          flex-flow: row wrap;
		  .timeline_hold & {
            display: flex;
          }
          &:before,
          &:after {
            display: none;
          }
          [class^=icon-] {
            font-size: 6px;
            opacity: 0;
            visibility: hidden;
            transition: background @primary-transition, border @primary-transition;
            border-radius: 50%;
            padding: 5px;
            margin: 0 0 0 10px;
            position: absolute;
            left: -99999px;
            .light & {
              border: 1px solid @bunker;
            }
            .dark & {
              background: @bunker;
              border: 1px solid transparent;
            }
          }
        }
      }
      input[type=checkbox] {
        &:checked {
          & + label {
            .light & {
              background: fade(@regent-gray, 9%);
            }
            .dark & {
              background: @shark;
            }
            [class^=icon-] {
              opacity: 1;
              visibility: visible;
              position: relative;
              left: auto;
            }
          }
        }
        &:disabled {
          & + label {
            color: @shuttle-gray;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  &_video {
    width: 100%;
    max-width: 426px;
    position: relative;
    float: left;
    [class^=icon-] {
      width: 92px;
      height: 68px;
      color: @elements-color;
      font-size: 34px;
      line-height: 68px;
      text-align: center;
      background: @deco-color;
      box-shadow: 0 3px 26px rgba(0, 0, 0, .81);
      padding: 0 0 0 5px;
      position: absolute;
      top: ~"calc(50% - 34px)";
      left: ~"calc(50% - 46px)";
    }
    &:hover {
      [class^=icon-] {
        border-color: @deco-color;
      }
    }
    img {
      width: 100%;
    }
  }
}
.sort {
  &_type {
    padding: 0 0 20px 0;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    &__items {
      font: 16px/1.2 @primary-font;
      transition: all @primary-transition;
      padding: 0 0 0 10px;
      margin: 0 0 10px 10px;
      .light & {
        color: fade(@bunker, 50%);
        border-left: 1px solid fade(@bunker, 50%);
      }
      .dark & {
        color: fade(@elements-color, 50%);
        border-left: 1px solid fade(@elements-color, 50%);
      }
      &:nth-of-type(1),
      &:nth-of-type(2) {
        border: none;
        padding: 0;
      }
    }
    &__links {
      text-decoration: underline;
      float: left;
      .light & {
        color: fade(@bunker, 80%);
        &:hover,
        &.active {
          color: @bunker;
          text-decoration: none;
        }
      }
      .dark & {
        color: fade(@elements-color, 80%);
        &:hover,
        &.active {
          color: @elements-color;
          text-decoration: none;
        }
      }
    }
  }
}
.headline {
  align-items: baseline;
  justify-content: space-between;
  display: flex;
}
.book {
  &_list {
    padding: 0 0 15px 0;
    display: flex;
    flex-flow: row wrap;
    &__items {
      width: 100%;
      font: 16px/1.2 @primary-font;
      transition: background @primary-transition;
      border: 1px solid @border-color;
      padding: 10px;
      margin: 0 0 20px 0;
      position: relative;
      .light & {
        background: @elements-color;
      }
      .dark & {
        background: @bunker;
      }
    }
    &__top {
      padding: 17px;
      align-items: flex-start;
      justify-content: space-between;
      display: flex;
      h3 {
        font: 28px/1.3 @secondary-font;
        padding: 0 20px 5px 0;
        a {
          text-decoration: none;
          &:hover {
            color: @deco-color;
          }
        }
      }
      .btn {
        flex: 0 0 auto;
      }
    }
    &__bottom {
      position: relative;
      padding: 17px;
      align-items: flex-start;
      display: flex;
      z-index: 1;
      &:before {
        content: "";
        opacity: .05;
        background: linear-gradient(@regent-gray 0%, @bunker 100%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    &__wrap {
      display: flex;
      flex-flow: row wrap;
      flex: 1 1 0;
    }
    &__desc {
      width: 100%;
      max-width: 400px;
      margin: 0 100px 0 0;
      &__items {
        font: 16px/1.2 @primary-font;
        border-top: 1px solid @border-color;
        padding: 11px 0;
        display: flex;
        &:first-child {
          border: none;
        }
      }
      &__title {
        transition: color @primary-transition;
        padding: 0 10px 0 0;
        flex: 0 0 50%;
        .light & {
          color: fade(@text-color, 50%);
        }
        .dark & {
          color: fade(@elements-color, 50%);
        }
      }
      &__txt {
        flex: 0 0 50%;
      }
    }
  }
  &_reader {
    &_hold {
      height: 791px;
      margin: 0 0 40px 0;
      display: flex;
    }
    &_side {
      transition: background @primary-transition;
      border-top: 1px solid @border-color;
      justify-content: space-between;
      display: flex;
      flex-flow: column nowrap;
      flex: 0 0 272px;
      .light & {
        background: transparent;
      }
      .dark & {
        background: @bunker;
      }
      h3 {
        font: bold 16px/1.2 @primary-font;
        text-transform: uppercase;
        border-bottom: 1px solid @border-color;
        padding: 15px 18px;
      }
      &__url {
        font: bold 11px/1.2 @primary-font;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        transition: all @primary-transition;
        padding: 14px;
        display: block;
        .light & {
          color: @text-color;
          border: 2px solid @text-color;
        }
        .dark & {
          color: @elements-color;
          border: 2px solid @elements-color;
        }
      }
    }
    &_list {
      border-bottom: 4px solid @border-color;
      padding: 15px 0;
      overflow: auto;
      flex: 1 1 0;
      &:last-of-type {
        border-bottom: none;
      }
      &.mCS_no_scrollbar {
        padding-right: 15px;
      }
      &__items {
        padding: 0 10px 0 0;
        align-items: center;
        justify-content: space-between;
        display: flex;
      }
      &__links {
        font: 16px/1.2 @primary-font;
        text-decoration: none;
        transition: color @primary-transition;
        padding: 4px 4px 4px 35px;
        flex: 1 1 0;
        .light & {
          color: @text-color;
        }
        .dark & {
          color: @elements-color;
        }
        &:hover {
          color: @deco-color;
        }
      }
      &__info {
        font-size: 0;
        text-decoration: none;
        transition: color @primary-transition;
        .light & {
          color: @dodger-blue;
        }
        .dark & {
          color: @almond;
        }
        &:hover {
          color: @deco-color;
        }
        [class^=icon-] {
          font-size: 16px;
        }
      }
    }
    &_block {
      transition: background @primary-transition;
      border-bottom: 1px solid @border-color;
      padding: 15px 0 15px 15px;
      overflow: auto;
      flex: 1 1 0;
      .light & {
        background: @elements-color;
      }
      .dark & {
        background: @ebony-clay;
      }
      &.mCS_no_scrollbar {
        padding-right: 15px;
      }
      p {
        font: 16px/1.2 @primary-font;
        padding: 0 0 15px 0;
        &:last-child {
          padding: 0;
        }
      }
    }
    &_content {
      background: @black;
      border-top: 1px solid @border-color;
      border-left: 1px solid @border-color;
      border-right: 1px solid @border-color;
      flex: 1 1 0;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.letters {
  &_hold {
    transition: background @primary-transition, border @primary-transition;
    padding: 20px 15px 20px 20px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    .light & {
      background: @elements-color;
      border-bottom: 1px solid @border-color;
    }
    .dark & {
      background: @ebony-clay;
      border-bottom: 1px solid transparent;
    }
  }
  &_list {
    padding: 0 15px 0 0;
    &__items {
      margin: 0 5px 5px 0;
      float: left;
    }
    &__links {
      width: 32px;
      height: 32px;
      color: @text-color;
      font: bold 18px/32px @primary-font;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: all @primary-transition;
      position: relative;
      float: left;
      .light & {
        background: fade(@regent-gray, 9%);
      }
      .dark & {
        background: @almond;
      }
      &.active,
      &:hover {
        color: @elements-color;
        background: @deco-color;
      }
      &.active {
        &:after {
          width: 0;
          height: 0;
          content: "";
          border-top: 5px solid @deco-color;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 0;
          position: absolute;
          left: ~"calc(50% - 5px)";
          bottom: -5px;
        }
      }
    }
  }
  &_toggle {
    &_hold {
      padding: 0 0 32px 0;
      justify-content: space-between;
      display: flex;
    }
    align-items: center;
    display: inline-flex;
    &__items {
      &--title {
        padding: 0 10px 0 0;
      }
    }
    input[type=radio] + &__label {
      height: 36px;
      text-decoration: none;
      transition: all @primary-transition;
      border: 1px solid @border-color;
      padding: 5px 15px;
      align-items: center;
      display: flex;
      &--first {
        border-radius: 18px 0 0 18px;
      }
      &--last {
        border-radius: 0 18px 18px 0;
      }
      .light & {
        color: @text-color;
        background: @elements-color;
      }
      .dark & {
        color: @elements-color;
      }
      &:before,
      &:after {
        display: none;
      }
    }
    input[type=radio]:checked + &__label {
      .light & {
        color: @elements-color;
        background: @text-color;
      }
      .dark & {
        color: @text-color;
        background: @elements-color;
      }
    }
  }
}
.words_list {
  &:last-of-type {
    padding: 0 0 20px 0;
  }
  &__items {
    transition: background @primary-transition;
    border-bottom: 1px solid @border-color;
    align-items: center;
    justify-content: space-between;
    display: flex;
    .light & {
      background: @elements-color;
    }
    .dark & {
      background: @bunker;
    }
    .site_external {
      margin: 0 38px 0 0;
    }
  }
  &__links {
    font: 32px/1.2 @secondary-font;
    text-decoration: none;
    transition: color @primary-transition;
    padding: 20px;
    flex: 1 1 0;
    .light & {
      color: @text-color;
    }
    .dark & {
      color: @elements-color;
    }
    &:hover {
      color: @deco-color;
    }
  }
}
.form {
  &_hold {
    transition: background @primary-transition;
    padding: 25px 25px 5px 25px!important;
    margin: 0 0 20px 0!important;
	border:0!important;
    .light & {
      background: @elements-color;
    }
    .dark & {
      background: @ebony-clay;
    }
  }
  &_elements {
    align-items: flex-end;
    display: flex;
    flex-flow: row wrap;
    &__items {
      margin: 0 25px 20px 0;
      position: relative;
      flex: 1 1 0;
      &--auto {
        flex: 0 0 auto;
      }
      &--full {
        flex: 0 0 100%;
      }
      &--split {
        margin-right: 0;
        flex: 0 0 ~"calc(50% - 10px)";
      }
      .btn {
        height: 50px;
      }
    }
    &__label {
      font: bold 16px/1.2 @primary-font;
      padding: 0 0 10px 0;
      display: block;
    }
    &__search {
      background: none;
      transition: color @primary-transition;
      padding: 0 15px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      .light & {
        color: @text-color;
      }
      .dark & {
        color: @elements-color;
      }
      &:hover {
        color: @deco-color;
      }
      [class^=icon-] {
        font-size: 26px;
      }
    }
  }
}
.alert,
.user {
  font: 16px/1.2 @primary-font;
  transition: all @primary-transition;
  padding: 15px;
  margin: 0 0 15px 0;
  &--removable {
    padding-right: 55px;
    position: relative;
  }
  &--success {
    color: #96b4a1;
    .light & {
      background: fade(#dbe3de, 88%);
    }
    .dark & {
      background: fade(#83998a, 34%);
    }
  }
  &--error,
  &-registration-error {
    border-top:0!important;
	padding: 15px!important;
    margin: 0 0 15px 0!important;
    color: #e08c6a;
    .light & {
      background: fade(#f6d8cc, 90%);
    }
    .dark & {
      background: fade(#6f413f, 80%);
    }
	ul{
	  display: inline-block;
      vertical-align: middle;
	}
  }
  &--warning {
    color: #e3d2ad;
    .light & {
      background: #f6efe0;
    }
    .dark & {
      background: fade(#ccbc99, 35%);
    }
  }
    &--success &__close {
    color: #96b4a1;
  }
  &--error &__close {
    color: #e08c6a;
  }
  &--warning &__close {
    color: #e3d2ad;
  }
  &__close {
    font-size: 0;
    text-decoration: none;
    padding: 17px;
    position: absolute;
    top: ~"calc(50% - 23px)";
    right: 0;
    &:hover {
      [class^=icon-] {
        transform: rotate(180deg);
      }
    }
    [class^=icon-] {
      font-size: 12px;
      transition: transform @primary-transition;
      display: inline-block;
    }
  }
}
.newsletter {
  h3 {
    font-size: 28px;
    align-items: center;
    display: flex;
    [class^=icon-] {
      font-size: 39px;
      margin: 0 10px 0 0;
    }
  }
}
.map {
  &_list {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    &__items {
      padding: 40px 20px 0 20px;
    }
    &__links {
      color: @elements-color;
      text-decoration: none;
      transition: color @primary-transition;
      position: relative;
      display: block;
      &:hover {
        color: @deco-color;
      }
      &:before {
        content: "";
        background: linear-gradient(rgba(187, 187, 187, 0) 0%, #0c1017 60%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      img {
        max-width: 100%;
      }
    }
    &__text {
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-flow: column nowrap;
      position: absolute;
      top: 70px;
      left: 20px;
      right: 20px;
      bottom: 20px;
      h3 {
        font: 28px/1.2 @secondary-font;
        position: relative;
        &:before {
          width: 64px;
          height: 1px;
          content: "";
          background: @deco-color;
          transform: translateX(-50%);
          position: absolute;
          left: 50%;
          bottom: -2px;
        }
      }
      h4 {
        font: 15px/1.2 @primary-font;
        padding: 25px 0 0 0;
      }
    }
  }
  &_main {
    height: ~"calc(100% + 40px)";
    min-height: 700px;
    margin: 0 0 -40px 0;
  }
  &_info_box {
    width: 398px;
    max-width: ~"calc(100% - 20px)";
    color: @text-color;
    opacity: 0;
    visibility: hidden;
    background: @elements-color;
    transition: all @primary-transition;
    box-shadow: 0 3px 25px rgba(0, 0, 0, .76);
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    display: flex;
    flex-flow: column nowrap;
	a, a:not([class]){
	  color:#314fe2;
	  text-decoration:none;
	  font-weight:700;
	}
    &.open {
      opacity: 1;
      visibility: visible;
    }
    &__close {
      color: @elements-color;
      font-size: 0;
      text-decoration: none;
      background: @bunker;
      padding: 9px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      &:hover {
        [class^=icon-] {
          transform: rotate(180deg);
        }
      }
      [class^=icon-] {
        font-size: 15px;
        transition: transform @primary-transition;
        display: inline-block;
      }
    }
    &__image {
      position: relative;
      display: block;
      img {
        width: 100%;
      }
      [class^=icon-] {
        color: @elements-color;
        font-size: 21px;
        background: @deco-color;
        padding: 6px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    &__text {
      padding: 15px 0 15px 15px;
      overflow: auto;
      &.mCS_no_scrollbar {
        padding-right: 15px;
      }
      p {
        font: 16px/1.7 @primary-font;
        &:last-child {
          padding: 0;
        }
      }
    }
	&__gallery {
      width: 100%;
      height: 141px;
      font-size: 0;
      padding: 0 0 15px 0;
      flex: 0 0 auto;
      &__panels {
        margin: 0 4px 0 0;
        display: inline-block;
        &:last-child {
          margin: 0;
        }
      }
      &__links {
        display: block;
        img {
          height: 126px;
        }
      }
      .slick-arrow {
        width: 32px;
        height: 32px;
        transition: all @primary-transition;
        border: none;
        border-radius: 0;
        position: absolute;
        top: ~"calc(50% - 16px)";
        .light & {
          color: @text-color;
          background: @elements-color;
        }
        .dark & {
          color: @elements-color;
          background: @text-color;
        }
        [class^=icon-] {
          font-size: 20px;
          line-height: 32px;
        }
      }
      .slick-prev {
        padding: 0 5px 0 0;
        left: 0;
      }
      .slick-next {
        padding: 0 0 0 5px;
        right: 0;
      }
      .slick-disabled {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
    &_frame {
    height: ~"calc(100% + 40px)";
    min-height: 700px;
    margin: 0 0 -40px 0;
    position: relative;
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &_burger {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &_sidebar {
    width: 280px;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all @primary-transition;
    position: absolute;
    top: 0;
    right: 0;
    .light & {
      background: @wild-sand;
    }
    .dark & {
      background: @ebony-clay;
    }
    &.open {
      opacity: 1;
      visibility: visible;
    }
    &__close {
      height: 72px;
      font-size: 13px;
      text-decoration: none;
      text-align: right;
      display: block;
      .light & {
        color: @text-color;
      }
      .dark & {
        color: @elements-color;
      }
      &:hover {
        [class^=icon-] {
          transform: rotate(180deg);
        }
      }
      [class^=icon-] {
        transition: transform @primary-transition;
        position: absolute;
        top: 28px;
        right: 28px;
      }
    }
    &__nav {
      max-height: ~"calc(100% - 72px)";
      overflow: auto;
      &__items {
        border-top: 1px solid transparent;
        &--sep {
          border-color: @deco-color;
        }
      }
      &__links {
        font: 15px/1.2 @primary-font;
        text-decoration: none;
        transition: all @primary-transition;
        padding: 12px 10px 12px 24px;
        display: block;
        .light & {
          color: @text-color;
        }
        .dark & {
          color: @elements-color;
        }
        &:hover,
        &.active {
          color: @elements-color;
          background: @deco-color;
        }
      }
	  &__sub {
        color: @text-color;
        font: 13px/1.9 @primary-font;
        background: @indian-khaki;
        padding: 15px;
        display: none;
      }
    }
  }
}
a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"],
.gm-style-cc {
  display: none !important;
}
.timeline {
  &_hold {
    min-height: ~"calc(100% + 40px)";
    padding: 10px 0 10px 10px;
    margin: 0 0 -40px 0;
    display: flex;
	input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]),
	textarea{
		border:none;
		height:30px;
	}
	.form-elements{
		border: 1px solid @border-color;
	}
  }
  &_wrap {
    width: ~"calc(100% - 315px)";
    height: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
    float: right;
    &_inner {
      width: 100%;
      height: 100%;
    }
	.infobox{
		background: #D23D00 0% 0% no-repeat padding-box;
		border-radius: 34px;
		color:#fff;
		padding: 6px 20px 5px 8px;
		text-decoration: none;
		position: absolute;
		left: 9px;
		bottom: 0;
		i{
			margin-right:5px;
			font-size: 20px;
			vertical-align: sub;
			line-height: 15px;
		}
	}
  }
  &_animation {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &__stars {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      .light & {
        background: @bunker;
      }
      .dark & {
        background: @elements-color;
      }
    }
  }
  &_year {
    position: relative;
    width: 6px;
    margin: 0 2px;
    height: 8px;
    &__top,
    &__bottom {
      position: absolute;
      display: flex;
      flex-flow: column;
      left: 0;
    }
    &__top {
      bottom: 100%;
    }
    &__bottom {
      top: 100%;
    }
    &_hold {
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      align-items: center;
      display: inline-flex;

    }
    &__event {
      width: 6px;
      height: 6px;
      cursor: pointer;
      transition: background @primary-transition;
      border-radius: 50%;
      margin: 2px 0;
      display: inline-block;
      opacity: 0;
      transform: translateX(-800px);
      position: relative;
      &:hover {
        background-color: #d5360e;
      }
      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        left: -2px;
        top: -2px;
        bottom: -2px;
        right: -2px;
      }
      .light & {
        background: @bunker;
      }
      .dark & {
        background: @elements-color;
      }
    }
    &__event:hover &__deco {
      transform: scale(3);
    }
    &__top &__event {
      .dark &,
      .light & {
        background: #EEE0C3;
        &:hover {
          background-color: #d5360e;
        }
      }
    }
    &__bottom &__event {
      .dark &,
      .light & {
        &:hover {
          background-color: #d5360e;
        }
      }
    }

    &__deco {
      width: 8px;
      height: 8px;
      transition: all .2s ease;
      border-radius: 50%;
      margin: -1px;
      position: relative;
      display: block;
      z-index: 1;
      &:hover {
        background: @deco-color;
      }
    }
    &__event:hover &__hover {
      z-index: 2;
    }
    &__hover {
      text-align: center;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      img {
        border: 3px solid @elements-color;
        border-radius: 50%;
        margin: 0 0 5px 0;
        width: 8px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
		min-height: 84px;
		background: #000;
      }
      &__text {
        top: 45px;
        position: absolute;
        left: 50%;
        margin-left: -60px;
        width: 120px;
        color: @elements-color;
        font: 12px/1.2 @primary-font;
        text-align: left;
        background: @ebony-clay;
        padding: 7px 12px;
        &__year {
          color: fade(@elements-color, 80%);
          padding: 5px 0 0 0;
          display: block;
        }
      }
    }
    &__hover-template {
      position: absolute;
      display: none;
      width: 1px;
      height: 1px;
      &.h-is-active {
        display: block;
      }
      .timeline_year__hover {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.grid {
  &_list {
    margin: 0 0 30px 0;
    &__items {
	float:left;
      &--col-1 {
        width: 25%;
      }
      &--col-2 {
        width: 50%;
      }
	  &:nth-child(1) img{
			width:320px;
			height:640px;
	   }
	   &:nth-child(2) img,
	   &:nth-child(5) img{
			width:640px;
			height:320px;
	  }
      img {
        width: 100%;
		width:320px;
		height:320px;
		object-fit: cover;
      }
    }
    &__links {
      text-decoration: none;
      transition: all @primary-transition;
      position: relative;
      display: block;
      &:hover {
        transform: scale(1.08);
        z-index: 1;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .light & {
        color: @text-color;
        &:before {
          background: linear-gradient(rgba(0, 0, 0, 0) 0%, @elements-color 100%);
        }
      }
      .dark & {
        color: @elements-color;
        &:before {
          background: linear-gradient(rgba(0, 0, 0, 0) 0%, @bunker 100%);
        }
      }
    }
    &__text {
      width: 100%;
      padding: 0 20px 20px 20px;
      position: absolute;
      left: 0;
      bottom: 0;
      h3 {
        font: bold 19px/1.4 @primary-font;
        padding: 0 0 14px 0;
      }
    }
  }
}
.results {
  font: 15px/1.2 @primary-font;
  text-align: center;
  border: 1px dashed @border-color;
  padding: 100px 10px;
  clear: both;
  .light & {
    color: fade(@text-color, 30%);
    background: @elements-color;
  }
  .dark & {
    color: fade(@elements-color, 30%);
    background: @bunker;
  }
}
.gallery {
  &_slider {
    margin: 0 -3px 30px -3px;
    &__panels {
      padding: 0 3px;
      h3 {
        font: 14px/1.36 @primary-font;
        padding: 0 10px 0 0;
      }
    }
    &__image {
      margin: 0 0 14px 0;
      display: block;
      img {
        width: 100%;
      }
    }
    .slick-arrow {
      top: ~"calc(50% - 50px)";
      .light &,
      .dark & {
        background: @bunker;
      }
    }
    .slick-prev {
      left: -15px;
    }
    .slick-next {
      right: -15px;
    }
    .slick-dots {
      padding: 30px 0 0 0;
      li {
        button {
          background: @text-color;
        }
      }
    }
  }
  &_grid {
    margin: 0 -2px 30px -2px;
    &__items {
      width: 20%;
      padding: 2px;
    }
    &__links {
      display: block;
      img {
        width: 100%;
      }
    }
  }
}
.aside {
  display:none;
  width: 36%;
  max-width: 312px;
  transition: background @primary-transition;
  margin: 100px 0 20px -70px;
  position: relative;
  float: left;
  .light & {
    background: @mercury;
  }
  .dark & {
    background: @ebony-clay;
  }
}
.search {
  &_results {
    padding: 0 0 15px 0;
    &__links {
      font: bold 19px/1.2 @primary-font;
      text-decoration: none;
      transition: color @primary-transition;
      padding: 7px;
      display: block;
      .light & {
        color: @text-color;
      }
      .dark & {
        color: @elements-color;
      }
      &:hover {
        color: @deco-color;
      }
    }
  }
}
.modal {
  width: 75%;
  filter: drop-shadow(0px 13px 26px rgba(0, 0, 0, .37));
  padding: 40px 30px 35px 30px;
  margin: 10px auto;
  position: relative;
  .light & {
    background: @elements-color;
  }
  .dark & {
    background: @ebony-clay;
  }
  &--mini {
    max-width: 500px;
  }
  h2 {
    font: 28px/1.2 @secondary-font;
    display: block;
  }
  h3 {
    font: bold 16px/1.2 @primary-font;
    padding: 0 0 18px 0;
  }
  .btn {
    margin-top: 10px;
  }
}
.footer {
  color: fade(@elements-color, 80%);
  font: 14px/1.5 @primary-font;
  background: lighten(@bunker, 3%);
  padding: 29px 5px;
  flex: none;
  &--intro {
    background: @bunker;
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .main {
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    flex-flow: row wrap;
  }
  &__copy {
    align-items: center;
    display: inline-flex;
    img {
      margin: 0 18px 0 0;
    }
  }
  &__info {
    padding: 0 0 0 110px;
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 0;
    order: 1;
    &__column {
      border-left: 1px solid @border-color;
      padding: 0 15px 0 18px;
      flex: 0 0 33.333%;
      h3 {
        font: bold 14px/1.2 @primary-font;
        padding: 0 0 16px 0;
      }
      p {
        font: 14px/1.44 @primary-font;
        padding: 0;
      }
      a {
        .dark & {
          color: fade(@elements-color, 80%);
          text-decoration: none;
        }
      }
    }
  }
}
.img_center {
  text-align: center;
  background: @black;
  &__link {
    position: relative;
    display: inline-block;
    [class^=icon-] {
      width: 32px;
      height: 32px;
      color: @elements-color;
      font-size: 17px;
      background: fade(@elements-color, 43%);
      border-radius: 4px;
      position: absolute;
      right: 4px;
      bottom: 4px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
  img {
    max-width: 100%;
    max-height: 50vh;
  }
}
input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]),
textarea {
  width: 100%;
  height: 50px;
  font: 16px @primary-font;
  transition: border @primary-transition;
  border: 1px solid @border-color;
  border-radius: 0;
  padding: 15px;
  .light & {
    color: @text-color;
    background: @elements-color;
  }
  .dark & {
    color: @elements-color;
    background: @ebony-clay;
  }
  .modal &,
  .newsletter &,
  .login & {
    .light & {
      background: @elements-color;
    }
    .dark & {
      background: darken(@ebony-clay, 5%);
    }
  }
  .newsletter &,
  .login & {
    max-width: 374px;
  }
}
textarea {
  height: 150px;
  resize: none;
  display: block;
  .modal & {
    height: 100px;
  }
}
input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])::placeholder,
textarea::placeholder {
  .light & {
    color: @text-color !important;
  }
  .dark & {
    color: @elements-color !important;
  }
  opacity: 1;
}
input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]):focus::placeholder,
textarea:focus::placeholder {
  color: transparent !important;
}
input.error:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]),
textarea.error {
  border: 1px solid @error !important;
}
input[type=checkbox],
input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  & + label {
    min-height: 20px;
    font: 16px/1.2 @primary-font;
    cursor: pointer;
    white-space: pre-wrap;
    padding-left: 30px;
    position: relative;
    align-items: center;
    display: inline-flex;
    flex-flow: row wrap;
    &:before {
      width: 20px;
      height: 20px;
      content: "";
      box-sizing: border-box;
      transform: translate(0, -50%);
      transition: all @primary-transition;
      position: absolute;
      top: 50%;
      left: 0;
    }
    &:after {
      opacity: 0;
      visibility: hidden;
      transition: all @primary-transition;
    }
  }
  &.error {
    & + label {
      &:before {
        border-color: @error;
      }
    }
  }
}
input[type=checkbox] {
  & + label {
    &:before {
      border-radius: 2px;
      .light & {
        border: 2px solid @bunker;
      }
      .dark & {
        border: 2px solid @elements-color;
      }
    }
    &:after {
      font: bold 12px icomoon;
      content: "\e912";
      transform: translate(0, -50%);
      position: absolute;
      top: 50%;
      left: 4px;
      .light & {
        color: @text-color;
      }
      .dark & {
        color: @elements-color;
      }
    }
  }
  &:checked {
    & + label {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
input[type=radio] {
  & + label {
    &:before {
      border-radius: 50%;
      .light & {
        border: 2px solid @bunker;
      }
      .dark & {
        border: 2px solid @elements-color;
      }
    }
    &:after {
      width: 10px;
      height: 10px;
      content: "";
      border-radius: 50%;
      transform: translate(0, -50%);
      position: absolute;
      top: 50%;
      left: 5px;
      .light & {
        background: @bunker;
      }
      .dark & {
        background: @elements-color;
      }
    }
  }
  &:checked {
    & + label {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.btn {
  height: 39px;
  font: 14px/1.2 @primary-font;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all @primary-transition;
  padding: 2px 23px 0 23px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  &--default {
    .light & {
      color: @text-color;
      border: 2px solid @text-color;
    }
    .dark & {
      color: @elements-color;
      border: 2px solid @elements-color;
    }
    &:hover {
      border-color: @deco-color;
    }
  }
  &--default-dark {
    color: @text-color;
    border: 2px solid @text-color;
    &:hover {
      color: @deco-color;
      border-color: @deco-color;
    }
  }
  &--primary {
    color: @elements-color;
    font-weight: bold;
    background: @deco-color;
    &:hover {
      background: darken(@deco-color, 15%);
    }
  }
  &--secondary {
    color: @text-color;
    font-weight: bold;
    background: @elements-color;
    &:hover {
      background: fade(@elements-color, 80%);
    }
  }
  &--block {
    width: 100%;
  }
  & + & {
    margin-left: 5px;
  }
}
.fl {
  float: left !important;
}
.fr {
  float: right !important;
}
.clearfix:after,
.clear {
  height: 0;
  content: "";
  clear: both;
  display: table;
}
.visually-hidden {
  position: absolute;
  top: auto;
  left: -99999px;
}
.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
	max-height:90vh;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.wp-block-image img{
	max-width:100%;
	height:auto;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-ripple span {
  position: absolute;
  border: 4px solid #d5360e;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple span:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 32px;
    height: 32px;
    opacity: 0;
  }
}
.addtoany_content .addtoany_list a{
   padding:0;
}
.a2a_s_facebook,
.a2a_s_email{
   background-color:#2a77f2!important;
}
.page .addtoany_content{
  display:none;
}

.tabs-panels-filter-js{
  display: none;
}
@media (max-width: 767px){
	.category .tabs_nav__links--mobile{
	  display:flex;
	}
}
.header .site_external{
	display: none;
}
.tag-links{
	width:100%;
}

.user-registration{
	background-color:transparent!important;
	margin:0!important;
	label{
		display:none;
	}
}
.user-registration-edit-profile{
	.section__block{
		padding:0;
	}
}
.dark .search-form button{
	color: #fff;
}
.light .search-form button{
	color: #141922;
}
.popup{
	width:fit-content;
	padding:0!important;
	img{
		max-width: 100%;
		max-height: 95vh;
	}
	.mfp-close{
		width:80px;
		height:80px;
		i{
			display:none;
		}
	}
}



// imports bottom
@import (less) "tablet.less";
@import (less) "mobile.less";
