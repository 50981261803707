h1,
.h1 {
  font: bold 50px/1.34 @secondary-font;
  padding: 0 0 32px 0;
  @media (max-width: @mobile-max) {
    font-size: 40px;
  }
}
h2,
.h2 {
  font: bold 40px/1.2 @secondary-font;
  padding: 0 0 20px 0;
  align-items: center;
  display: flex;
  @media (max-width: @mobile-max) {
    font-size: 30px;
  }
  [class^=icon-] {
    font-size: 38px;
    margin: 0 10px 0 0;
  }
}
h3 {
  font: bold 24px/1.2 @secondary-font;
  padding: 0 0 25px 0;
}
.underline {
  span {
    position: relative;
    z-index: 1;
    &:before {
      height: 8px;
      content: "";
      background: @corn;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1px;
      z-index: -1;
    }
  }
}
ul,
ol {
  &:not([class]) {
    font: 18px/1.6 @primary-font;
    text-align: left;
    padding: 0 0 25px 0;
    @media (max-width: @mobile-max) {
      font-size: 15px;
    }
    li {
      padding: 0 0 0 18px;
      position: relative;
      &:before {
        position: absolute;
      }
    }
  }
}
ul {
  &:not([class]) {
    li {
      &:before {
        width: 5px;
        height: 5px;
        content: "";
        background: @text-color;
        border-radius: 50%;
        top: 11px;
        left: 2px;
        @media (max-width: @mobile-max) {
          top: 9px;
        }
      }
    }
  }
}
ol {
  &:not([class]) {
    counter-reset: li;
    flex-flow: column nowrap;
    display: flex;
    li {
      &:before {
        font-weight: bold;
        content: counter(li) ".";
        counter-increment: li;
        top: 0;
        left: 0;
      }
    }
  }
}
p {
  font: 18px/1.6 @primary-font;
  padding: 0 0 25px 0;
  @media (max-width: @mobile-max) {
    font-size: 15px;
  }
  &.info {
    color: @shuttle-gray;
    font: 14px/1.5 @primary-font;
  }
}
a {
  &:not([class]) {
    text-decoration: underline;
    transition: color @primary-transition;
    .light & {
      color: @text-color;
    }
    .dark & {
      color: @elements-color;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
.table-responsive {
  overflow-x: auto;
  margin: 0 0 25px 0;
  clear: both;
}
table {
  &:not([class]) {
    width: 100%;
    font: 16px/1.3 @primary-font;
    border: 1px solid @border-color;
    border-bottom: none;
    th {
      text-align: left;
      text-transform: uppercase;
      padding: 32px 20px 22px 20px;
      .light & {
        background: fade(@regent-gray, 9%);
      }
      .dark & {
        background: @bunker;
      }
    }
    td {
      border-bottom: 1px solid @border-color;
      padding: 25px 20px;
      .light & {
        background: @elements-color;
      }
      .dark & {
        background: @bunker;
      }
    }
    th,
    td {
      transition: background @primary-transition;
      @media (max-width: @mobile-max) {
        padding: 15px;
      }
    }
  }
}
.table {
  &__title {
    min-width: 200px;
    font: 21px/1.2 @secondary-font;
  }
  &__search {
    text-decoration: none;
    transition: color @primary-transition;
    .light & {
      color: @text-color;
    }
    .dark & {
      color: @elements-color;
    }
    &:hover {
      color: @deco-color;
    }
    [class^=icon-] {
      font-size: 26px;
    }
  }
}
@media (max-width: @mobile-max) {
  .table-responsive {
    padding: 0 0 5px 0;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      .light & {
        background: rgba(0, 0, 0, .2);
      }
      .dark & {
        background: rgba(255, 255, 255, .2);
      }
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
}
.deco {
  &_headline {
    width: 54%;
    max-width: 432px;
    color: @black;
    font: 28px/1.34 @secondary-font;
    background: @indian-khaki;
    padding: 25px 50px;
    & p{
      font: 28px/1.34 @secondary-font;
	  padding:0;
    }
    @media (min-width: @tablet-min) and (max-width: @tablet-max) {
      width: 62%;
      padding: 25px;
    }
    @media (max-width: @mobile-max) {
      width: ~"calc(100% + 30px)";
  	max-width: none;
      font-size: 20px;
      padding: 20px;
    }
    &--left {
      margin: 0 30px 25px -82px;
      float: left;
      @media (min-width: @tablet-min) and (max-width: @tablet-max) {
        margin: 0 30px 25px -50px;
      }
      @media (max-width: @mobile-max) {
        margin: 0 0 25px -30px;
      }
    }
    &--right {
      margin: 0 -82px 25px 30px;
      float: right;
      @media (min-width: @tablet-min) and (max-width: @tablet-max) {
        margin: 0 -50px 25px 30px;
      }
      @media (max-width: @mobile-max) {
        margin: 0 -30px 25px 0;
      }
    }
  }
  &_quotes {
    height: 40px;
    color: @black;
    font: 50px/1 @secondary-font;
    justify-content: center;
    display: flex;
    &:before,
    &:after {
      width: 100%;
      height: 1px;
      content: "";
      background: @black;
    }
    &:before {
      margin: 16px 16px 0 0;
    }
    &:after {
      margin: 16px 0 0 16px;
    }
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
