// colors with hex values
@shark: #22242b;
@white: #fff;
@black: #000;
@mercury: #e5e5e5;
@wild-sand: #f6f6f6;
@spanish-white: #f6efe0;
@almond: #eee0c3;
@rodeo-dust: #ccbc99;
@indian-khaki: #bcad8a;
@corn: #ebbf00;
@dodger-blue: #2a77f2;
@natural-gray: #888681;
@bunker: #0c1017;
@mirage: #141922;
@ebony-clay: #292e3f;
@shuttle-gray: #5b6273;
@regent-gray: #7e8ca4;
@tia-maria: #d5360e;
@monza: #d60017;

// reassign color vars to semantic color scheme
@text-color: @shark;
@elements-color: @white;
@deco-color: @tia-maria;

// font
@primary-font: Roboto, sans-serif;
@secondary-font: "Playfair Display", serif;

// other elelemnts
@primary-transition: .5s ease;
@border-color: rgba(126, 140, 164, .31);
@error: @monza;

// media queries
@mobile-max: 767px;
@tablet-min: 768px;
@tablet-max: 1279px;

// loader
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.icon-spinner {
  color: @deco-color;
  font-size: 50px;
  animation: spinner 2s infinite linear;
  display: inline-block;
}
