@media (min-width: @tablet-min) and (max-width: @tablet-max) {
  body {
    &.modal_open {
      max-height: 100%;
      overflow: hidden;
    }
  }
  .hamburger {
    margin: 0 0 0 20px;
    display: flex;
  }
  .nav {
    &_main {
      width: 100%;
      background: lighten(@bunker, 3%);
      transition: transform .5s cubic-bezier(.2,0,.3,-.48);
      transform: translateY(~"calc(-100% - 30px)");
      padding: 8px 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      &.open {
        transition: transform .6s cubic-bezier(.2,1,.3,1.14);
        transform: translateY(80px);
      }
      &__items {
        width: 100%;
        padding: 0;
      }
      &__links {
        height: auto;
        padding: 8px 15px;
      }
    }
  }
  .section {
    &__block {
      padding: 35px 35px 10px 35px;
	  .img_center{
		margin: 0 -35px;
	  }
	  figcaption{
		margin-right:-35px;
		margin-left:-35px;
	  }
    }
  }
  .home {
	&_slider {
      &__panels {
        height: 315px;
      }
    }
    &_video {
      width: 48%;
    }
    &_text {
      width: 52%;
      padding: 0 0 0 30px;
    }
  }
  .story_list {
    &__links {
      &:hover {
        transform: scale(1);
      }
    }
    &--additional &__links {
      h3 {
        font-size: 20px;
        top: 20px;
      }
    }
    &--additional &__links__bottom {
      padding: 20px;
    }
  }
  .share,
  .section__block .addtoany_content {
    margin: -12px -5px 15px 0;
    justify-content: flex-start;
    &__items,
	.addtoany_list a {
      margin: 0 10px 0 0;
    }
  }
  .filter {
    &_wrap {
      width: 100%;
      margin: 0 0 15px 0;
    }
  }
  .book {
    &_list {
      &__desc {
        max-width: ~"calc(50% - 50px)";
        margin: 0 50px 0 0;
      }
    }
    &_reader {
      &_side {
        flex: 0 0 200px;
      }
    }
  }
  .grid {
    &_list {
      &__items {
        &--col-1 {
          width: 50%;
        }
        &--col-2 {
          width: 100%;
        }
      }
      &__links {
        &:hover {
          transform: scale(1);
        }
		img{
			width: 100%!important;
			height:320px!important;
		}
      }
    }
  }
  .gallery {
    &_grid {
      &__items {
        width: 25%;
      }
    }
  }
  .aside {
    margin: 90px 0 20px -70px;
  }
  .footer {
    &--intro {
      padding-top: 29px;
      padding-bottom: 29px;
    }
    &__info {
      padding: 0 0 30px 0;
      flex: 0 0 100%;
      order: 0;
    }
  }
}
