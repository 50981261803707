@media (max-width: @mobile-max) {
  body {
    min-width: 320px;
    &.modal_open {
      max-height: 100%;
      overflow: hidden;
    }
  }
  .header {
    padding: 0;
  }
  .site {
    &_logo {
      margin: 0;
      &__desktop {
        display: none;
      }
      &__mobile {
        display: block;
      }
    }
    &_external {
      .header & {
        margin: 0;
        span {
          display: none;
        }
        [class^=icon-] {
          margin: 0;
        }
      }
    }
    &_switch {
      &__wrap {
        height: 28px;
        .light &,
        .dark &{
          border: none;
        }
        &__txt {
          display: none;
        }
        &--night,
        &--day {
          padding: 0;
          [class^=icon-] {
            margin: 0;
          }
        }
        [class^=icon-] {
          width: 28px;
          height: 28px;
        }
      }
    }
    &_user {
      margin: 0 0 0 14px;
    }
    &_close {
      display: none;
    }
  }
  .hamburger {
    margin: 0 0 0 15px;
    display: flex;
  }
  .nav {
    &_main {
      width: 100%;
      background: lighten(@bunker, 3%);
      transition: transform .5s cubic-bezier(.2,0,.3,-.48);
      transform: translateY(~"calc(-100% - 30px)");
      padding: 8px 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      &.open {
        transition: transform .6s cubic-bezier(.2,1,.3,1.14);
        transform: translateY(80px);
      }
      &__items {
        width: 100%;
        padding: 0;
      }
      &__links {
        height: auto;
        padding: 8px 15px;
      }
    }
    &_lang {
      margin: 0;
    }
    &_side {
      &__links {
        padding: 9px 15px 9px 20px;
      }
    }
    &_bottom {
      width: 100%;
      text-align: center;
      margin: 0 0 20px 0;
      order: 0;
      &__items {
        border: none;
        padding: 10px 0;
        position: relative;
        display: block;
        &:first-child {
          &:after {
            display: none;
          }
        }
        &:after {
          width: 10px;
          height: 1px;
          content: "";
          background: fade(@elements-color, 50%);
          position: absolute;
          top: 0;
          left: ~"calc(50% - 5px)";
        }
      }
    }
  }
  .body {
    &--inner {
      padding: 0 0 35px 0;
    }
  }
  .section {
    padding: 25px 0;
    &--content {
      width: 100%;
      padding: 0;
    }
    &--intro-new {
      padding: 30px 0 250px 0;
    }
    &--intro-old {
      padding: 40px 0 100px 0;
    }
    &__block {
      padding: 25px 20px 0 20px;
	  figcaption{
		margin-right:-20px;
		margin-left:-20px;
	  }
      &:first-of-type {
        padding-right: 20px;
        .share,
		.addtoany_content{
          margin-right: 0;
        }
		figcaption{
		  margin-right:-20px;
		}
      }
	  .img_center{
		margin: 0 -20px;
	  }
    }
  }
  .home {
    &_slider {
	&__panels {
        height: 400px;
      }
      &__hold {
        max-width: none;
      }
      .slick-arrow {
        left: -99999px;
      }
    }
    &_video {
      width: 100%;
    }
    &_text {
      width: 100%;
      padding: 20px 0 0 0;
    }
  }
  .intro {
    &--new {
      padding: 35px 25px 30px 25px;
      h1 {
        font-size: 30px;
      }
    }
    &--old {
      padding: 32px 25px 32px 25px;
      h2 {
        font: 28px/1.3 @secondary-font;
        padding: 0 0 35px 0;
      }
    }
  }
  .tabs {
    margin: 0;
    &_nav {
      &_hold {
        display: none;
      }
      &__links {
        &--mobile {
          height: auto;
          margin: 0 0 10px 0;
          display: flex;
        }
        &:after {
          display: none;
        }
      }
    }
    &_sub_nav {
      display: none;
      &__links {
        &--mobile {
          width: 100%;
          padding: 15px 20px;
          margin: 0 0 20px 0;
          display: block;
        }
      }
    }
  }
  .media_list {
    margin: 0 -5px 0 0;
    &__items {
      width: 100%;
      @media (min-width: (@mobile-max - 307px)) {
        width: 50%;
      }
    }
  }
  .story_list {
    margin: 0 -5px 0 0;
    &__items {
      width: 100%;
      @media (min-width: (@mobile-max - 307px)) {
        width: 50%;
      }
    }
    &__links {
      &:hover {
        transform: scale(1);
        z-index: auto;
      }
    }
    &--additional &__links {
      h3 {
        font-size: 20px;
        top: 20px;
      }
    }
    &--additional &__links__bottom {
      padding: 20px;
    }
  }
  .share,
  .section__block .addtoany_content  {
    margin: -10px 0 15px 0;
  }
  .filter {
    &_hold {
      flex-flow: row wrap;
      .tabs_panels {
        padding: 15px 0;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    &_nav {
      display: none;
      &__links {
        &--mobile {
          margin: 1px 0;
          display: block;
        }
      }
    }
    &_wrap {
      width: 100%;
      margin: 0 0 15px 0;
    }
	&_toggle {
      font: bold 18px/1.2 @primary-font;
      text-decoration: none;
      transition: color @primary-transition;
      border: 1px solid @deco-color;
      padding: 10px 40px 10px 12px;
      position: relative;
      display: flex;
      .light & {
        color: @text-color;
      }
      .dark & {
        color: @elements-color;
      }
      &.active {
        [class^=icon-] {
          transform: rotate(270deg);
        }
      }
      [class^=icon-] {
        font-size: 16px;
        transition: transform @primary-transition;
        transform: rotate(90deg);
        position: absolute;
        top: ~"calc(50% - 8px)";
        right: 15px;
      }
    }
    &_elements {
	  padding: 10px 0 0 0;
      display: none;
      &__items {
        width: 100%;
        padding: 0 0 1px 0;
      }
    }
    &_video {
      max-width: none;
    }
  }
  .book {
    &_list {
      padding: 15px 0 0 0;
      &__top {
        padding: 5px 10px 20px 10px;
        flex-flow: row wrap;
        h3 {
          width: 100%;
          padding: 0 0 20px 0;
        }
      }
      &__bottom {
        padding: 10px;
        flex-flow: row wrap;
      }
      &__wrap {
        padding: 0 0 15px 0;
        flex: 0 0 100%;
      }
      &__desc {
        max-width: none;
        margin: 0;
      }
    }
    &_reader {
      &_hold {
        height: auto;
        flex-flow: row wrap;
      }
      &_side {
        flex: 0 0 100%;
      }
      &_list {
        height: 120px;
        flex: 0 0 auto;
      }
      &_block {
        height: 220px;
        flex: 0 0 auto;
      }
      &_content {
        height: 791px;
        flex: 0 0 100%;
      }
    }
  }
  .letters {
    &_hold {
      flex-flow: row wrap;
    }
    &_list {
      width: 100%;
      padding: 0 0 15px 0;
    }
  }
  .words_list {
    &__links {
      font-size: 22px;
    }
  }
  .form {
    &_hold {
      padding: 20px 20px 0 20px;
    }
    &_elements {
      align-items: flex-end;
      &__items {
        margin: 0 0 20px 0;
        flex: 0 0 100%;
        .btn {
          width: 100%;
        }
      }
    }
  }
  .map {
    &_list {
      flex-flow: row wrap;
      &__text {
        top: 30px;
      }
    }
    &_main {
      margin: 0 0 -35px 0;
    }
  }
  .timeline {
    &_hold {
      margin: 0 0 -35px 0;
    }
    &_wrap {
      width: ~"calc(100% - 200px)";
    }
  }
  .grid {
    &_list {
      &__items {
        &--col-1,
        &--col-2 {
          width: 100%;
        }
      }
      &__links {
        &:hover {
          transform: scale(1);
        }
		img{
			width: 100%!important;
			height:320px!important;
		}
      }
    }
  }
  .gallery {
    &_slider {
      .slick-prev {
        left: -15px;
      }
      .slick-next {
        right: -15px;
      }
    }
    &_grid {
      &__items {
        width: 50%;
      }
    }
  }
  .aside {
    width: 100%;
    max-width: none;
    margin: 0 0 20px 0;
    order: -1;
  }
  .modal {
    padding: 40px 22px 35px 22px;
  }
  .footer {
    &--intro {
      padding-top: 29px;
      padding-bottom: 29px;
    }
    &__copy {
      width: 100%;
      text-align: center;
      flex-flow: column nowrap;
      img {
        margin: 0 0 20px 0;
        display: block;
      }
    }
    &__info {
      padding: 0;
      flex: 0 0 100%;
      order: 0;
      &__column {
        border-left: none;
        border-bottom: 1px solid @border-color;
        padding: 20px 0;
        flex: 0 0 100%;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          margin-bottom: 25px;
        }
      }
    }
  }
  .tabs_nav_hold{
	//display:block;
	li{
		width:100%;
		i{
		display:none;
		}
	}
  }
  .home_slider{
	display:none;
  }
  .home_text .btn+.btn{
	margin-top:4px;
	margin-left:0;
  }
}
