// custom select
.selectric-wrapper {
  transition: background @primary-transition;
  .light & {
    background: @elements-color;
  }
  .dark & {
    background: @ebony-clay;
  }
  .dark .newsletter &,
  .dark .login & {
    background: darken(@ebony-clay, 5%);
  }
  .newsletter &,
  .login & {
    max-width: 374px;
  }
  .selectric {
    background: none;
    border: 1px solid @border-color;
    .label {
      height: 48px;
      font: 16px/48px @primary-font;
      transition: color @primary-transition;
      margin: 0 35px 0 15px;
      .light & {
        color: @text-color;
      }
      .dark & {
        color: @elements-color;
      }
    }
    .icon-triangle-down {
      font-size: 7px;
      position: absolute;
      top: ~"calc(50% - 3px)";
      right: 18px;
    }
  }
  .selectric-items {
    box-shadow: none;
    border: 1px solid @border-color;
    .light & {
      background: @elements-color;
    }
    .dark & {
      background: @ebony-clay;
    }
    li {
      padding: 10px 10px 10px 15px;
      .light & {
        color: @text-color;
        &:hover,
        &.highlighted {
          background: darken(@elements-color, 5%);
        }
      }
      .dark & {
        color: @elements-color;
        &:hover,
        &.highlighted {
          background: darken(@ebony-clay, 5%);
        }
      }
    }
  }
  &.selectric-below {
    .selectric-items {
      border-top: none;
    }
  }
  &.selectric-above {
    .selectric-items {
      border-bottom: none;
    }
  }
}

// sticky
.sticky-wrapper {
  height: auto !important;
}

// range slider
.irs {
  .irs-min,
  .irs-max {
    display: none;
  }
  .irs-bar {
    background: @deco-color;
  }
  .irs-handle {
    cursor: pointer;
    background: @deco-color;
    border: 2px solid @ebony-clay;
    box-shadow: none;
    &.state_hover,
    &:hover {
      background: @deco-color;
    }
    &:before {
      content: "";
      border: 6px solid @elements-color;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .irs-single,
  .irs-from,
  .irs-to {
    color: @elements-color;
    font: 13px/1.2 @primary-font;
    background: @mirage;
    border-radius: 0;
    &:before {
      border-top-color: @mirage;
    }
  }
}

// custom scroll
.mCSB_inside>.mCSB_container {
  margin-right: 28px;
}
.mCSB_scrollTools {
  width: 28px;
}
.mCSB_scrollTools {
  opacity: 1;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  transition: background @primary-transition;
  .dark & {
    background: @elements-color;
  }
  .light &,
  .map_info_box & {
    background: darken(@mercury, 27%);
  }
}
.mCSB_scrollTools .mCSB_draggerRail {
  background: transparent;
}

// custom modal
.mfp-close-btn-in .mfp-close {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 1;
  opacity: 1;
  transition: transform @primary-transition;
  padding: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  .light & {
    color: @text-color;
  }
  .dark & {
    color: @elements-color;
  }
  &:hover {
    transform: rotate(180deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// slider
.slick-slider {
  .slick-arrow {
    width: 40px;
    height: 40px;
    color: @elements-color;
    transition: all @primary-transition;
    border: 1px solid fade(@elements-color, 33%);
    border-radius: 50%;
    position: absolute;
    top: ~"calc(50% - 20px)";
    z-index: 1;
    .light & {
      background: @bunker;
    }
    .dark & {
      background: fade(@bunker, 49%);
    }
    &:hover {
      color: @deco-color;
      border-color: @deco-color;
    }
    [class^=icon-] {
      font-size: 20px;
      line-height: 38px;
    }
  }
  .slick-dots {
    font-size: 0;
    text-align: center;
    li {
      height: 8px;
      margin: 0 4px;
      align-items: center;
      display: inline-flex;
      button {
        width: 5px;
        height: 5px;
        text-indent: -99999px;
        transition: all @primary-transition;
        border-radius: 50%;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: -4px;
          left: -4px;
          right: -4px;
          bottom: -4px;
        }
      }
      &.slick-active {
        button {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
}
.map_info_box .addtoany_content_top{
	display:none;
}