@font-face {
  font-family: 'icomoon';
  src:
    url('../fonts/icomoon.ttf?86k72e') format('truetype'),
    url('../fonts/icomoon.woff?86k72e') format('woff'),
    url('../fonts/icomoon.svg?86k72e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-city:before {
  content: "\e900";
}
.icon-eye:before {
  content: "\e919";
}
.icon-collapse:before {
  content: "\e917";
}
.icon-fullscreen:before {
  content: "\e918";
}
.icon-book-abc:before {
  content: "\e916";
}
.icon-newsletter:before {
  content: "\e901";
}
.icon-envelope:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e904";
}
.icon-spinner:before {
  content: "\e911";
}
.icon-info:before {
  content: "\e910";
}
.icon-arrow-long-prev:before {
  content: "\e90f";
}
.icon-moon:before {
  content: "\e905";
}
.icon-sun:before {
  content: "\e906";
}
.icon-arrow-next:before {
  content: "\e907";
}
.icon-arrow-prev:before {
  content: "\e908";
}
.icon-book:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e90a";
}
.icon-external:before {
  content: "\e90b";
}
.icon-library:before {
  content: "\e90c";
}
.icon-script:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e90e";
}
.icon-triangle-down:before {
  content: "\e912";
}
.icon-triangle-right:before {
  content: "\e913";
}
.icon-user:before {
  content: "\e914";
}
.icon-video:before {
  content: "\e915";
}
.icon-virtuali:before {
  content: "\e91a";
}
.icon-vilnius-gediminas:before {
  content: "\e91b";
}
